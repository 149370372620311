import React, { useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import Home from "../Pages/Home";
import About from "../Pages/About";
import Blog from "../Pages/Blog";
import ContactUs from "../Pages/ContactUs";
import Director from "../Pages/Director";
import Properties from "../Pages/Properties";
import Plotes from "../Pages/Plots";
import PropertyDetails from "../Pages/PropertyDetails";
import GlobalRoutes from "../Constants/GlobalRoutes.js";
import AdminLogin from "../Pages/Admin/Login";
import EnquiryDetails from "../Pages/Admin/EnquiryDetails.jsx";
import { getAuthToken } from "../api/tokenHelper.js";
import AdminDashbaord from "../Pages/Admin/Dashboard.jsx";
import AddProperty from "../Pages/Admin/AddProperty.jsx";
import PropertyList from "../Pages/Admin/PropertyList.jsx";

export default function AppRoutes() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    // Check authentication status on load
    useEffect(() => {
        const token = getAuthToken();
        if (token) {
            setIsAuthenticated(true);
        }
    }, []);

    // Public routes (for all users)
    const userRoutes = [
        {
            path: "/",
            children: [
                { index: true, element: <Home /> },
                { path: GlobalRoutes.publicRoutes.about, element: <About /> },
                { path: GlobalRoutes.publicRoutes.director, element: <Director /> },
                { path: GlobalRoutes.publicRoutes.blogs, element: <Blog /> },
                { path: GlobalRoutes.publicRoutes.contactUs, element: <ContactUs /> },
                { path: GlobalRoutes.publicRoutes.plots, element: <Properties /> },
                { path: GlobalRoutes.publicRoutes.properties, element: <Plotes /> },
                { path: GlobalRoutes.publicRoutes.propertiesDetails, element: <PropertyDetails /> },
                { path: GlobalRoutes.publicRoutes.AdminLogin, element: <AdminLogin /> },
            ],
        },
    ];

    const AuthRoute = [
        {
            path: GlobalRoutes.publicRoutes.AdminLogin, element: <AdminLogin />
        },
    ];

    const adminRoutes = [
        { path: GlobalRoutes.adminRoutes.admin, element: <AdminDashbaord /> },
        { path: GlobalRoutes.adminRoutes.enquiryDetails, element: <EnquiryDetails /> },
        { path: GlobalRoutes.adminRoutes.AddProperty, element: <AddProperty /> },
        { path: GlobalRoutes.adminRoutes.PropertyList, element: <PropertyList /> },

    ];


    const routes = [
        ...userRoutes,
        ...(isAuthenticated ? adminRoutes : AuthRoute),
    ];


    return useRoutes(routes);
}
