const Images = {
    logo: require('../../src/assets/img/Oxford-Logo.png'),
    whiteLogo: require('../../src/assets/img/logo-white.png'),
    locationIcon: require('../../src/assets/img/location-icon.png'),
    callIcon: require('../../src/assets/img/call-icon.png'),
    gmailIcon: require('../../src/assets/img/gmail-icon.png'),
    about1: require('../../src/assets/img/slider_2.png'),
    about2: require('../../src/assets/img/slider_4.png'),
    aboutImg: require('../../src/assets/img/about.jpg'),
    aboutImg1: require('../../src/assets/img/about-1.jpg'),
    Director: require('../../src/assets/img/director.png'),
};

export default Images;