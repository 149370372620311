import React, { useEffect, useState } from "react";
import { getList } from '../../api/globalFunction';
import { apiEndpoints } from '../../api/apiEndpoints';
import Loader from "../../Components/Shared/Loader";
import ErrorModal from "../../Components/Shared/ErrorModal";
import { Table, Card, CardBody } from "react-bootstrap";

function EnquiryTable({ limit = null }) {
    const [contactDetails, setContactDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchItems = async () => {
            setLoading(true);
            try {
                const response = await getList(apiEndpoints.EnquiryList);
                if (response && response.Data) {
                    setContactDetails(response.Data); // Extract the Data array
                } else {
                    setError("No data found.");
                }
            } catch (err) {
                setError("Failed to fetch contact details.");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        fetchItems();
    }, []);

    const displayedContacts = limit ? contactDetails.slice(0, limit) : contactDetails;

    if (loading) {
        return <Loader isLoading={loading} />;
    }


    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Number</th>
                                <th>Subject</th>
                                <th>Message</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayedContacts.map((contact, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{contact.Name || "N/A"}</td>
                                    <td>{contact.Email || "N/A"}</td>
                                    <td>{contact.Number || "N/A"}</td>
                                    <td>{contact.Subject || "N/A"}</td>
                                    <td>{contact.Message || "N/A"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            {error && (
                <ErrorModal
                    show={!!error}
                    message={error}
                    onHide={() => setError(null)}
                />
            )}
        </React.Fragment>
    );
}

export default EnquiryTable;
