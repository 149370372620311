import React from "react";
import { Navbar, Container, Nav } from 'react-bootstrap';
import Images from "../../Constants/Images";
import { Link } from 'react-router-dom';
import GlobalRoutes from '../../Constants/GlobalRoutes';
import { useState, useEffect } from 'react';

function NavBar() {
    return (
        <div className='navbar-layout'>
            <Container>
                <Navbar expand="lg">
                    <Container fluid>
                        <Link className='navbar-brand' to="/">
                            <img src={Images.logo} alt="logo" />
                        </Link>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav className="ms-auto mb-2 my-lg-0">
                                <Link className='nav-link' to="/">Home</Link>
                                <Link className='nav-link' to={GlobalRoutes.publicRoutes.properties}>Properties</Link>
                                <Link className='nav-link' to={GlobalRoutes.publicRoutes.director}>Director</Link>
                                <Link className='nav-link' to={GlobalRoutes.publicRoutes.about}>About</Link>
                                <Link className='nav-link' to={GlobalRoutes.publicRoutes.blogs}>Blog</Link>
                                <Link className='nav-link' to={GlobalRoutes.publicRoutes.contactUs}>Contact</Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Container>
        </div>
    );
}

export default NavBar;
