import React from 'react';
import { Accordion, Navbar, Container } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import GlobalRoutes from '../../../Constants/GlobalRoutes';
import Images from '../../../Constants/Images';
import { removeAuthToken } from '../../../api/tokenHelper';
import { toast, ToastContainer } from 'react-toastify';

export const SIDEBAR_NAV_MENU_ITEMS = {
  // admin sidebar mainmenu
  mainMenu: [
    {
      name: "Dashboard",
      icon: <i class="bx bxs-home icon"></i>,
      path: GlobalRoutes.adminRoutes.admin,
    },
    {
      name: "Profile",
      icon: <i class="bx bxs-buildings icon"></i>,
      path: GlobalRoutes.adminRoutes.admin,
    },
    {
      name: "enquiry",
      icon: <i class="bx bxs-buildings icon"></i>,
      path: GlobalRoutes.adminRoutes.enquiryDetails,
    },
  ],

  subMenu: [
    {
      name: "property",
      icon: <i class="bx bxs-book-open icon"></i>,
      children: [
        {
          name: "property list",
          path: GlobalRoutes.adminRoutes.PropertyList,
        },
        {
          name: "property add",
          path: GlobalRoutes.adminRoutes.AddProperty,
        },
      ],
    },
  ],
  bottomMenu: [
    {
      name: "Logout",
      icon: <i class='bx bxs-log-out-circle icon'></i>,
      path: "Logout",
    },
  ],
};
function AdminSideBar() {
  const navigate = useNavigate();

  const handleLogout = () => {
    removeAuthToken();
    toast.success("You have been logged out successfully!");
    navigate(GlobalRoutes.publicRoutes.AdminLogin);
  };

  return (
    <React.Fragment>
      <div className="nav-layout">
        <Navbar>
          <Container fluid>
            <Link className="navbar-brand" to={GlobalRoutes.adminRoutes.admin}>
              <img src={Images.logo} alt="Logo" />
            </Link>
          </Container>
        </Navbar>
      </div>
      <div className='sidebar' id="sidebar">
        <div className="menu-bar">
          <div className="menu">
            <ul className="menu-links">
              {SIDEBAR_NAV_MENU_ITEMS.mainMenu.map((item, index) => (
                <li key={index} className="nav-link">
                  <Link to={item.path}>
                    {item.icon}
                    <span>{item.name}</span>
                  </Link>
                </li>
              ))}
              <li className="menu-dropdown">
                <Accordion>
                  {SIDEBAR_NAV_MENU_ITEMS.subMenu.map((subItem, index) => (
                    <Accordion.Item eventKey={String(index)} key={index}>
                      <Accordion.Header>
                        {subItem.icon}
                        <span>{subItem.name}</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          {subItem.children.map((subChild, subIndex) => (
                            <li className="nav-link" key={subIndex}>
                              <Link to={subChild.path}>
                                <i className="bx bx-radio-circle-marked icon"></i>
                                <span>{subChild.name}</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </li>
            </ul>
          </div>
          <div className="bottom-content">
            {SIDEBAR_NAV_MENU_ITEMS.bottomMenu.map((item, index) => (
              <li
                key={index}
                className="nav-link"
                onClick={handleLogout}
              >
                <Link to={item.path}>
                  {item.icon}
                  <span>{item.name}</span>
                </Link>
              </li>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AdminSideBar;