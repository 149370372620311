import React from "react";
import AdminSideBar from "../../Components/Admin/Shared/AdminSideBar";
import EnquiryTable from "../../Components/Admin/EnquiryTable";

function EnquiryDetails() {
    return (
        <React.Fragment>
            <AdminSideBar />
            <div className='page-wrapper'>
                <div className="home-section">
                    <h5 className="admin-head-title">enquiry details</h5>
                    <EnquiryTable />
                </div>
            </div>
        </React.Fragment>
    );
}

export default EnquiryDetails;
