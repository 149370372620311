import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, CardBody, CardText, CardTitle, Col, Container, Row, Button } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import FormikControl from "../../Formik/FormikControl";
import Images from "../../Constants/Images";
import GlobalRoutes from "../../Constants/GlobalRoutes";
import { addItem } from "../../api/globalFunction";
import { apiEndpoints } from "../../api/apiEndpoints";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

function LoginForm() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    const validationSchema = Yup.object({
        UserName: Yup.string().required("User name is required"),
        UserPassword: Yup.string().required("Password is required"),
    });

    const initialValues = {
        UserName: "Admin",
        UserPassword: "Admin_oxford123@",
    };

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            const payload = {
                UserName: values.UserName,
                UserPassword: values.UserPassword,
                EncData:
                    "0595e10d5f84707ff4f43f628294bed9e944379d10d6499ce5749b1298aa5bf4dc854720263b49b976a9c12f2368ec4e159f30a733248b7d6817e5f424e0cd61",
            };

            const response = await addItem(apiEndpoints.LoginForm, payload);

            if (response?.Status) {
                toast.success(response?.Message || "Login successful!");

                if (response.Data?.Token) {
                    Cookies.set("authToken", response.Data.Token);
                    setIsAuthenticated(true);
                    navigate(GlobalRoutes.adminRoutes.admin); // Use navigate here
                }
            } else {
                toast.error(response?.Message || "Invalid username or password");
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong. Please try again.");
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <section className="admin-login">
            <Container>
                <Row>
                    <Col lg={5} className="mx-auto">
                        <Card>
                            <CardBody>
                                <div className="admin-logo">
                                    <img src={Images.logo} alt="Admin Logo" />
                                </div>
                                <CardTitle>Login</CardTitle>
                                <CardText>
                                    Log in with your data that you entered during registration
                                </CardText>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={onSubmit}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <Row className="g-3 row-cols-1">
                                                <FormikControl
                                                    control="textInput"
                                                    label="User Name*"
                                                    name="UserName"
                                                    type="text"
                                                    className="col"
                                                />
                                                <FormikControl
                                                    control="textInput"
                                                    label="Password*"
                                                    name="UserPassword"
                                                    type="password"
                                                    className="col"
                                                />
                                                <Col>
                                                    <div className="form-group">
                                                        <Button
                                                            variant="success"
                                                            type="submit"
                                                            disabled={isSubmitting}
                                                        >
                                                            {isSubmitting
                                                                ? "Logging in..."
                                                                : "Login"}
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
export default LoginForm;
