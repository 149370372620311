import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../Components/Shared/Navbar";
import AppBreadcrumb from "../Components/Shared/AppBreadcrumb";
import CtaAction from "../Components/Cta/CtaAction"
import Footer from "../Components/Shared/Footer";
import WebHeading from "../Components/Shared/WebHeading";
import Images from "../../src/Constants/Images";

function About() {
    const aboutPage = [
        { label: "Home", href: "/", active: false },
        { label: "About Us", href: "/About", active: true },
    ];
    return (
        <React.Fragment>
            <NavBar />
            <AppBreadcrumb items={aboutPage} title="about us" />
            <section class="about-director">
                <Container>
                    <Row class="row g-4">
                        <Col xxl={4} xl={4} lg={4} md={12} class="d-flex">
                            <img src={Images.aboutImg} alt="about" />
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={12}>
                            <WebHeading
                                subheading="About Oxford Realtors"
                                title="Top Property Management Company in Gurgaon"
                                description="Welcome to Oxford Realtors, where we turn your own home dreams
                                    into reality. Oxford Realtors is a leading Gurugram based real estate company that was
                                    launched in 2007 by Mr. Vikash Arora. We are dedicated to providing expert services,
                                    expertise and guidance to our valued clients. Our team of professionals will guide you on
                                    every step of the way, whether you're buying, selling, or investing.
                                    Through our online portal (oxfordrealtors.co.in), you can explore
                                    all the luxury properties which are available for buying or selling in Gurgaon. By
                                    contacting us you can easily book your visit to explore the properties and location. Our
                                    single platform is designed to cater all the property needs like Apartment's, Flats, Builder
                                    floor, Villas, Luxuries Home, and Offices. Buying our own home is everyone's dream but they
                                    don't have good knowledge about properties so in this case Oxford Realtors helping them to
                                    understand the real estate market and providing a proper guidance from property search to
                                    buy."
                            />
                            <div class="about-counter">
                                <Row class="row-cols-xxl-4 row-cols-xl-4 row-cols-lg-4 row-cols-md-2 row-cols-1 g-3">
                                    <Col>
                                        <h2>15+</h2>
                                        <p>Years of Experienced</p>
                                    </Col>
                                    <Col>
                                        <h2>500k+</h2>
                                        <p>Properties Listed</p>
                                    </Col>
                                    <Col>
                                        <h2>100+</h2>
                                        <p>Location Covered</p>
                                    </Col>
                                    <Col>
                                        <h2>100+</h2>
                                        <p>Properties Sold</p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <CtaAction />
            <section class="about-section">
                <Container>
                    <Row class="row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-1 row-cols-1 g-4">
                        <Col class="d-flex">
                            <img src={Images.about1} alt="about" />
                        </Col>
                        <Col class="d-flex">
                            <WebHeading
                                subheading="Know Us More"
                                title="You Will Love Our Services"
                                description="Your satisfaction is our top priority. We take the time to
                                    understand your specific needs and preferences, and tailor our service to meet and exceed
                                    your expectations. Your journey with us is personal and stress free. We have a wide range of
                                    properties in Gurgaon location. Whether you’re looking for a luxury starter home, luxury
                                    property, or a profitable investment, we have something for everyone in our extensive
                                    portfolio. Explore our diverse range of properties and find the perfect match for your
                                    lifestyle and financial goals."
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section class="about-section">
                <Container>
                    <Row class="row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-1 row-cols-1 g-4">
                        <Col class="d-flex">
                            <WebHeading
                                title="Our Mission"
                                description="Oxford Realtors should be the first choice for property buyers who's looking for apartments, Flats, Villas, Luxuries Home, Builder Floors and Offices."
                            />
                            <WebHeading
                                title="Our Vision"
                                description="May everyone's dream of having our own home come true in less paperwork and no moving about."
                            />
                        </Col>
                        <Col class="d-flex">
                            <img src={Images.about2} alt="about" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </React.Fragment>
    )
}
export default About;