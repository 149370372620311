import React, { useState } from "react";
import { Card, CardBody, Col, Row, Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import FormikControl from "../../Formik/FormikControl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminSideBar from "../../Components/Admin/Shared/AdminSideBar";
import { addItem } from "../../api/globalFunction";
import { apiEndpoints } from "../../api/apiEndpoints";

function AddProperty() {
  const [loading, setLoading] = useState(false);

  const initialValues = {
    Heading: "",
    Overview: "",
    Location: "",
    Highlights: "",
    Features: "",
    WhyChoose: "",
    images: null,
  };

  const validationSchema = Yup.object({
    Heading: Yup.string().required("Property Title is required"),
    Overview: Yup.string().required("Overview is required"),
    Location: Yup.string().required("Location is required"),
    Highlights: Yup.string().required("Highlights are required"),
    Features: Yup.string().required("Features are required"),
    WhyChoose: Yup.string().required("Reasons to choose are required"),
    images: Yup.mixed().required("Images are required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true); 
      const endpoint = apiEndpoints.PropertyAdd;
      const data = {
        Heading: values.Heading,
        Overview: values.Overview,
        Location: values.Location,
        Highlights: values.Highlights,
        Features: values.Features,
        WhyChoose: values.WhyChoose,
        Images: [], 
      };

      if (values.images) {
        Array.from(values.images).forEach((file) => {
          data.Images.push({
            ImagePath: file, 
          });
        });
      }

      // Make the API call using the utility function
      const response = await addItem(endpoint, data);
      const apiMessage = response?.Message || "Property added successfully!";
      toast.success(apiMessage); // Display success toast
      resetForm(); // Reset the form on successful submission
    } catch (error) {
      console.error("Submission error:", error); // Log error for debugging
      const apiErrorMessage =
        error.response?.data?.Message || "Error adding the property. Please try again.";
      toast.error(apiErrorMessage); // Display error toast
    } finally {
      setLoading(false); // End the loading state
    }
  };


  return (
    <React.Fragment>
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="home-section">
          <h5 className="admin-head-title">Add Property</h5>
          <div className="add-property">
            <Card>
              <CardBody>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting, setFieldValue }) => (
                    <Form>
                      <Row className="row-cols-1 g-3">
                        <FormikControl
                          control="textInput"
                          label="Property Title*"
                          name="Heading"
                          type="text"
                          className="col"
                        />
                        <FormikControl
                          control="textInput"
                          label="Location*"
                          name="Location"
                          type="text"
                          className="col"
                        />
                        <FormikControl
                          control="textareaInput"
                          label="Overview*"
                          name="Overview"
                          type="text"
                          className="col"
                        />
                        <FormikControl
                          control="textareaInput"
                          label="Highlights*"
                          name="Highlights"
                          type="text"
                          className="col"
                        />
                        <FormikControl
                          control="textareaInput"
                          label="Features*"
                          name="Features"
                          type="text"
                          className="col"
                        />
                        <FormikControl
                          control="textareaInput"
                          label="Why Choose*"
                          name="WhyChoose"
                          type="text"
                          className="col"
                        />
                        <FormikControl
                          control="fileInput"
                          label="Images*"
                          type="file"
                          name="images"
                          className="col"
                          onChange={(event) => {
                            setFieldValue("images", event.target.files);
                          }}
                          multiple
                        />
                        <Col>
                          <div className="form-group">
                            <Button
                              variant="success"
                              type="submit"
                              disabled={isSubmitting || loading}
                            >
                              {loading
                                ? "Submitting..."
                                : isSubmitting
                                  ? "Submitting..."
                                  : "Submit"}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddProperty;
