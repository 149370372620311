const GlobalRoutes = {
    publicRoutes: {
        about: "/about-us",
        blogs: "/our-blogs",
        director: "/director",
        contactUs: "/contact-us",
        plots: "/plots",
        properties: "/properties",
        propertiesDetails: "/property/properties-details",
        AdminLogin: "/login",
    },
    adminRoutes: {
        admin: "/dashboard",
        enquiryDetails: "/enquiry-details",
        AddProperty: "/add-properties",
        PropertyList: "/property-list",
    },
};

export default GlobalRoutes;

