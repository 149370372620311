import React from "react";
import AdminSideBar from "../../Components/Admin/Shared/AdminSideBar";
import { Row, Col, Card, CardBody, CardTitle } from "react-bootstrap";
import EnquiryTable from "../../Components/Admin/EnquiryTable";

function AdminDashbaord() {
    return (
        <React.Fragment>
            <AdminSideBar />
            <div className='page-wrapper'>
                <div className="home-section">
                    <h5 className="admin-head-title">dashboard</h5>
                    <div className="top-dashboard-card">
                        <Row className="row-cols-xxl-4 row-cols-xl-4 row-cols-lg-4 row-cols-md-2 row-cols-1 g-4">
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle>total property</CardTitle>
                                        <h3>52</h3>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle>total property</CardTitle>
                                        <h3>52</h3>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle>total property</CardTitle>
                                        <h3>52</h3>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle>total property</CardTitle>
                                        <h3>52</h3>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className="recent-enquiry">
                        <h5 className="admin-head-title">recent enquiry</h5>
                        <EnquiryTable limit={5} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default AdminDashbaord;