    import React, { useState } from "react";
    import { Card, CardBody, Col, Row, Button } from "react-bootstrap";
    import { Formik, Form } from "formik";
    import * as Yup from "yup";
    import FormikControl from "../../Formik/FormikControl";
    import { toast } from "react-toastify";
    import "react-toastify/dist/ReactToastify.css";
    import Loader from "../Shared/Loader";
    import { addItem } from "../../api/globalFunction";
    import { apiEndpoints } from "../../api/apiEndpoints";

    function ContactForm({ rowClass = "row-cols-1 g-3" }) {
        const [loading, setLoading] = useState(false);

        const validationSchema = Yup.object({
            name: Yup.string().required("Name is required"),
            email: Yup.string().email("Invalid email format").required("Email is required"),
            number: Yup.string()
                .matches(/^[0-9]{10}$/, "Must be a valid 10-digit number")
                .required("Number is required"),
            subject: Yup.string().required("Subject is required"),
            message: Yup.string().required("Message is required"),
        });

        const initialValues = {
            name: "",
            email: "",
            number: "",
            subject: "",
            message: "",
        };

        const onSubmit = async (values, { resetForm }) => {
            try {
                setLoading(true);
                const endpoint = apiEndpoints.contactUsSubmit;
                const data = {
                    Name: values.name,
                    Email: values.email,
                    Number: values.number,
                    Subject: values.subject,
                    Message: values.message,
                };

                const response = await addItem(endpoint, data);
                const apiMessage = response?.Message || "Form submitted successfully!";
                toast.success(apiMessage);
                resetForm();
            } catch (error) {
                console.error("Submission error:", error);
                const apiErrorMessage =
                    error.response?.data?.Message || "Error submitting the form. Please try again.";
                toast.error(apiErrorMessage);
            } finally {
                setLoading(false);
            }
        };

        return (
            <div className="enquiry-form">
                {loading && <Loader isLoading={loading} />}
                {!loading && (
                    <Card>
                        <CardBody>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                            >
                                <Form>
                                    <Row className={`g-4 ${rowClass}`}>
                                        <Col xxl={12} xl={12} lg={12} md={12}>
                                            <Card.Title>Send a Message!</Card.Title>
                                        </Col>
                                        <FormikControl
                                            control="textInput"
                                            label="Enter Name*"
                                            name="name"
                                            type="text"
                                            className="col"
                                        />
                                        <FormikControl
                                            control="textInput"
                                            label="Email*"
                                            name="email"
                                            type="email"
                                            className="col"
                                        />
                                        <FormikControl
                                            control="textInput"
                                            label="Number*"
                                            name="number"
                                            type="text"
                                            className="col"
                                            maxLength="10"
                                        />
                                        <FormikControl
                                            control="textInput"
                                            label="Subject*"
                                            name="subject"
                                            type="text"
                                            className="col"
                                        />
                                        <FormikControl
                                            control="textareaInput"
                                            label="Message*"
                                            name="message"
                                            className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12"
                                        />
                                        <Col>
                                            <div className="form-group">
                                                <Button
                                                    variant="success"
                                                    type="submit"
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </Formik>
                        </CardBody>
                    </Card>
                )}
            </div>
        );
    }

    export default ContactForm;
