import React from "react";
import { Container } from "react-bootstrap";
import NavBar from "../Components/Shared/Navbar";
import AppBreadcrumb from "../Components/Shared/AppBreadcrumb";
import Footer from "../Components/Shared/Footer";
import Images from "../Constants/Images";
function Director() {
    const directorPage = [
        { label: "Home", href: "/", active: false },
        { label: "director", href: "/Director", active: true }
    ];
    return (
        <React.Fragment>
            <NavBar />
            <AppBreadcrumb items={directorPage} title="director" />
            <section class="about-director text-center">
                <Container>
                    <div class="heading-section">
                        <h2 class="heading-title">Our Director</h2>
                        <img src={Images.Director} alt="Profile" class="mb-3 rounded-pill border" style={{ height: '10rem', width: '10rem' }} />
                        <h5>Vikas Arora</h5>
                        <h6>MBA, M.Com</h6>
                        <h4>Director</h4>
                        <p class="heading-description">Vikas Arora has 15 years + experience in Real Estate Sales &amp; Finance
                            Oxford
                            Realtors is his own
                            Professional Company, Deals in sale purchase leasing of properties in Gurgaon, Delhi NCR.Deals in
                            Hotels, Hospitals, Schools, Offices &amp; Housing All Over India.Vikas Arora Is Also an Expert In Real
                            Estate &amp; provides PROPERTY SOLUTION’S.Check out the YouTube &amp; Instagram Page.</p>
                        <h5>Vikas Arora</h5>
                        <p class="heading-description">There are moments in life when meticulous planning and in-depth research
                            isn’t enough. No matter how many
                            people have been conferred, there is still an iota of doubt in the mind. This moment comes for the
                            most
                            crucial decisions in life, and one such moment is buying a home. No matter how many people have been
                            involved, you are still unsure and forced to take that LEAP OF FAITH. At such a moment you need just
                            1
                            industry expert, who is trustworthy, with a legacy of helping people in the situation the same as
                            yours
                            and has exceeded expectations. These qualities are what our clients can expect from us &amp; our promise
                            is
                            to deliver it at every moment. We are the associates, who not just stand during the LEAP OF FAITH,
                            but
                            we will take it with you. We will make sure that every doubt is erased &amp; you look forward to seeing
                            your
                            dream home come to life.</p>
                    </div>
                </Container>
            </section>
            <Footer />
        </React.Fragment>
    )
}
export default Director;