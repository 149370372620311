import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Images from "../../Constants/Images";
import { Link } from "react-router-dom";
import GlobalRoutes from "../../Constants/GlobalRoutes";
function Footer() {
    return (
        <footer className="footer">
            <div className="upper-footer">
                <Container className="container">
                    <Row className="row row-cols-xxl-4 row-cols-xl-4 row-cols-lg-4 row-cols-md-2 row-cols-1 g-4">
                        <Col>
                            <div className="about-footer">
                                <img src={Images.whiteLogo} alt="logo" />
                                <p className="card-text">Oxford Realtors is an expertly overseen land administrations, property
                                    financier and counseling organization based out of Gurgaon/Gurugram which gives a wide
                                    exhibit
                                    of administrations down every one of the verticals which incorporates Commercial</p>
                                <Link to="/about">Read More<i className='bx bx-chevron-right'></i></Link>
                                <ul className="footer-icons">
                                    <li>
                                        <a href="https://www.youtube.com/channel/UC4UErz-7HjuCjS5ml049PsQ" target="_blank">
                                            <i className='bx bxl-youtube'></i>
                                        </a>
                                    </li>
                                    <li><a href="https://www.facebook.com/oxford.realtors" target="_blank"><i
                                        className='bx bxl-facebook'></i></a></li>
                                    <li><a href="https://www.instagram.com/oxfordrealtorsin/" target="_blank"><i
                                        className='bx bxl-instagram'></i></a></li>
                                    <li><a href="https://www.tumblr.com/oxfordrealtors" target="_blank"><i
                                        className='bx bxl-tumblr'></i></a></li>
                                    <li><a href="https://www.quora.com/profile/Vikas-Arora-473" target="_blank"><i
                                        className='bx bxl-quora'></i></a></li>
                                    <li><a href="https://api.whatsapp.com/send?phone=+91-9999637378&amp;text=Oxford Realtors"
                                        target="_blank"><i className='bx bxl-whatsapp'></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/oxford-realtors/" target="_blank"><i
                                        className='bx bxl-linkedin'></i></a></li>
                                    <li><a href="https://www.pinterest.com/oxford_realtors/" target="_blank"><i
                                        className='bx bxl-pinterest'></i></a></li>
                                    <li><a href="https://twitter.com/oxford_realtors" target="_blank"><i
                                        className='bx bxl-twitter'></i></a></li>
                                </ul>
                            </div>
                        </Col>
                        <Col>
                            <div className="footer-links">
                                <h5>Quick Links</h5>
                                <ul>
                                    <li>
                                        <Link to="/"><i className="bx bx-chevron-right"></i>Home</Link>
                                    </li>
                                    <li>
                                        <Link to={GlobalRoutes.publicRoutes.properties}><i className="bx bx-chevron-right"></i>Properties</Link>
                                    </li>
                                    <li>
                                        <Link to={GlobalRoutes.publicRoutes.director}><i className="bx bx-chevron-right"></i>Director</Link>
                                    </li>
                                    <li>
                                        <Link to={GlobalRoutes.publicRoutes.about}><i className="bx bx-chevron-right"></i>About</Link>
                                    </li>
                                    <li>
                                        <Link to={GlobalRoutes.publicRoutes.blogs}><i className="bx bx-chevron-right"></i>Blog</Link>
                                    </li>
                                    <li>
                                        <Link to={GlobalRoutes.publicRoutes.contactUs}><i className="bx bx-chevron-right"></i>Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col>
                            <div className="footer-links">
                                <h5>Popular Cities</h5>
                                <ul>
                                    <li>
                                        <a href="properties.html"><i className='bx bx-chevron-right'></i> Haryana(10 Properties)</a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col>
                            <div className="footer-links">
                                <h5>Quick Links</h5>
                                <ul>
                                    <li>
                                        <i className='bx bxs-map-pin'></i>
                                        <p>Ambience Creacions, Old Delhi-Gurgaon Road, Sector 22, Gurgaon</p>
                                    </li>
                                    <li>
                                        <i className='bx bxs-phone-call'></i>
                                        <a href="tel:9999637378">+91-9999-637-378</a>
                                    </li>
                                    <li>
                                        <i className='bx bxs-envelope'></i>
                                        <a href="mailto:oxfordrealtors@gmail.com">Oxfordrealtors@gmail.com</a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="lower-footer">
                <div className="container">
                    <p>Copyright ©2024 All rights reserved </p>
                </div>
            </div>
        </footer>
    )
}
export default Footer;