export const apiEndpoints = {
  // contact-us
  EnquiryList: '/contactus',
  contactUsSubmit: '/contactus/submit',

  // login
  LoginForm: '/Login',

  // old-properties 
  OldpropertiesList: '/Villas',

  // properties
  propertiesList: '/properties',
  allproperty:'/propertiesList',
  PropertyAdd: '/properties/create',
  PropertyUpdate: '/properties/update',
  PropertyDelete: '/properties/delete',
};

// api/properties/{id}/uploadImage

// api/properties/{id}/image/{imageId}

// api/properties/{id}/image/{imageId}

// api/properties/{id}/image/{imageId}