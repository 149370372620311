import React, { useState, useEffect } from "react";
import AdminSideBar from "../../Components/Admin/Shared/AdminSideBar";
import { apiEndpoints } from '../../api/apiEndpoints';
import { getList } from '../../api/globalFunction';
import Loader from "../../Components/Shared/Loader";
import { Table, Card, CardBody } from "react-bootstrap";
import ErrorModal from "../../Components/Shared/ErrorModal";

const PropertyList = () => {
    const [loading, setLoading] = useState(false);
    const [allPropertyList, setAllPropertyList] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchItems = async () => {
            setLoading(true);
            try {
                const response = await getList(apiEndpoints.allproperty);

                console.log("API Response:", response); // Log the full response

                if (response && response.data) {
                    setAllPropertyList(response.data); // Save the fetched data in state
                } else {
                    setError("No data found.");
                }
            } catch (err) {
                setError("Failed to fetch property details.");
                console.error("Error fetching property details:", err);
            } finally {
                setLoading(false);
            }
        };
        fetchItems();
    }, []);

    // Check if data is being properly set in the state
    useEffect(() => {
        console.log("Fetched Properties List:", allPropertyList);
    }, [allPropertyList]);

    if (loading) {
        return <Loader isLoading={loading} />;
    }

    return (
        <React.Fragment>
            <AdminSideBar />
            <div className="page-wrapper">
                <div className="home-section">
                    <Card>
                        <CardBody>
                            {error && <ErrorModal message={error} />}
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Heading</th>
                                        <th>Overview</th>
                                        <th>Location</th>
                                        <th>Highlights</th>
                                        <th>Features</th>
                                        <th>Why Choose</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allPropertyList.length > 0 ? (
                                        allPropertyList.map((property, index) => (
                                            <tr key={property.PropertyId}>
                                                <td>{index + 1}</td>
                                                <td>{property.Heading}</td>
                                                <td>{property.Overview}</td>
                                                <td>{property.Location}</td>
                                                <td>{property.Highlights}</td>
                                                <td>{property.Features}</td>
                                                <td>{property.WhyChoose}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="7" className="text-center">No data available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PropertyList;
